.auth-row * {
	font-weight: 700;
}
.auth-title-container {
	background: #ffffff;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.03);
}
.auth-title {
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	letter-spacing: -0.01em;
	color: #33302e;
}
.auth-title-mobile {
	font-size: 25px;
	line-height: 130%;
	letter-spacing: -0.02em;
	font-feature-settings: "pnum" on, "lnum" on;
	color: #33302e;
}
.auth-accroche-text {
	letter-spacing: -0.01em;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #999591;
}

.required-text {
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.03em;
	text-transform: uppercase;
	color: #cccac8;
	font-weight: 700;
	margin: 0;
}

.auth-link {
	font-size: 16px;
	line-height: 20px;
	font-feature-settings: "liga" off;
	color: #F2BA8C;
	text-decoration: none;
}
.logo-container {
	display: flex;
	justify-content: center;
}

.auth-link:hover {
	text-decoration: underline;
	color: #c3a083;
}
.forgot-accroche-text {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #66605c;
}
.auth-redirect-question {
	font-size: 16px;
	line-height: 18px;
	color: #999591;
}
.password-validation-terms-title {
	font-size: 16px;
	line-height: 21px;
	letter-spacing: -0.01em;
	color: #33302e;
	font-family: "Urbanist", sans-serif;
}
.password-rule-item {
	font-size: 14px;
	line-height: 21px;
	letter-spacing: -0.01em;
	color: #c3a083;
}
.fas-text-success {
	color: #2EB75C !important;
}
