.top-bar {
	width:100% !important;
	position: fixed;
	padding: 20px;
	background-color: #fff;
	right: 0;
	z-index: 10;
	top: 0;
}
.auth-user-avatar {
	width: 40px;
	height: 40px;
	border: 2px solid #c3a083;
	background-color: #fff1e6;
}
.auth-sigle {
	font-family: "Cormorant Garamond";
	font-size: 16px;
	line-height: 130%;
	text-align: center;
	letter-spacing: -0.02em;
	font-feature-settings: "pnum" on, "lnum" on;
	color: #33302e;
}
.top-bar-btn button.dropdown-toggle::after {
	display: none;
}
.btn_close:focus{
	box-shadow: 0 0 0 0.1rem #c3a083 !important;
}
